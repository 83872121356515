<template>
  <div>
    <div class="fs-18 font-weight-medium v-text-ctr">
      Selecciona método de pago
    </div>
    <v-radio-group v-model="radioGroup">
      <v-expansion-panels v-model="panel" :disabled="disabled" multiple>
        <v-expansion-panel elevation="0" style="border: 1px solid #e0e0e0">
          <v-expansion-panel-header>
            <v-radio
              label="Transferencia"
              color="#001F5B"
              value="Transferencia"
            ></v-radio>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="v-text-ctr">
              Por favor, inserte el número de voucher del depósito y una foto
              del mismo.
            </p>
            <v-row>
              <v-col cols="12" md="6">
                <ul
                  class="font-weight-medium pa-0"
                  style="text-decoration: none; list-style: none"
                >
                  <li>Bancolombia</li>
                  <li>320 7545755855</li>
                  <li>Cuennta interbancaria</li>
                  <li>00120388949002000</li>
                </ul>
              </v-col>
              <v-col cols="12" md="6">
                <div class="fs-18 font-weight-medium v-text-ctr">
                  Nº de voucher
                </div>
                <v-text-field solo label="Nº de voucher"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                  <v-img
                  alt="Vuetify Name"
                  class="shrink mt-1 mr-3"
                  contain
                  width="100"
                  src="~@/assets/image22.png"
                  />

              </v-col>
              <v-col cols="12" md="6">
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel style="border: 1px solid #e0e0e0">
          <v-expansion-panel-header>
            <v-radio
              label="Contraenterega"
              color="#001F5B"
              value="Contraenterega"
            ></v-radio>
          </v-expansion-panel-header>
          <v-expansion-panel-content> Some content </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-radio-group>

    <div class="fs-18 font-weight-medium v-text-ctr mb-4">
      Cupón de descuento
    </div>

    <v-row>
      <v-col cols="12" md="6">
        <div class="fs-18 font-weight-medium v-text-ctr">Código</div>
        <v-text-field solo label="Código"
        prepend-inner-icon="mdi-ticket-percent-outline"></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "PaymentMethod",
  data: () => ({
    panel: [0],
    disabled: false,
    readonly: false,
    radioGroup: 1,
  }),
};
</script>

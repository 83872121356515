<template>
  <v-form>
    <v-container class="pt-16">
      <div class="text-h4 font-weight-bold v-text-ctr">Finalizar compra</div>
      <v-row class="py-14">
        <v-col cols="12" md="8">
          <template>
            <v-stepper v-model="e1" alt-labels elevation="0">
              <v-stepper-header style="box-shadow:none;">
                <v-stepper-step :complete="e1 > 1" step="1">
                  Datos de entrega
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 2" step="2">
                  Métodos de pago
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <PersonalInformation />
                  <DeliveryComponent />
                  <div class="d-flex justify-end">
                    <v-btn
                        text
                      color="#FF7D2D"
                    >Guardar dirección
                    </v-btn>
                    <v-btn
                      class="px-14"
                      style="color: #ffffff"
                      color="#FF7D2D"
                      @click="e1 = 2"
                    >
                      Siguiente
                    </v-btn>
                  </div>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <PaymentMethod />
                  <div class="d-flex justify-end">
                    <v-btn
                      class="px-14"
                      style="color: #ffffff"
                      color="#FF7D2D"
                      :to="'/compra-exitosa'"
                    >
                      Siguiente
                    </v-btn>
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </template>
        </v-col>
        <v-col cols="12" md="4">
          <SummaryComponent/>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<style scoped>
.v-stepper--alt-labels .v-stepper__step {
    align-items: baseline;
}
</style>
<script>
import PersonalInformation from "@/components/checkout/PersonalInformation.vue";
import DeliveryComponent from "@/components/checkout/DeliveryComponent.vue";
import PaymentMethod from "@/components/checkout/PaymentMethod.vue";
import SummaryComponent from "@/components/checkout/SummaryComponent.vue";

export default {
  name: "Register",
  components: {
    PersonalInformation,
    DeliveryComponent,
    PaymentMethod,
    SummaryComponent,
  },
  data: () => ({
    e1: 1,
  }),
};
</script>

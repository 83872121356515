<template>
  <v-app>
    <v-app-bar class="nav_c hidden-sm-and-down" app color="#001F5B">
      <div class="d-flex align-center">
        <v-btn
          text
          :to="'/home'">
          <v-img
            alt="Vuetify Name"
            class="shrink mt-1 hidden-sm-and-down"
            contain
            min-width="100"
            src="~@/assets/Logo.png"
          />
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center justify-center">
        <v-text-field
          dense
          solo
          label="Buscar"
          prepend-inner-icon="mdi-search"
          hide-details="auto"
          rounded
          style="
    border-radius: 20px;"
        ></v-text-field>
      </div>
      <v-spacer></v-spacer>
      <div class="mx-2">
        <v-btn color="#FF7D2D" dark class="text-capitalize">
          Iniciar sesión
        </v-btn>
      </div>
      <div class="mx-2">
        <v-badge color="#FF7D2D" overlap>
          <v-img
            alt="Vuetify Name"
            class="shrink mt-1 hidden-sm-and-down"
            src="~@/assets/transport.png"
          />
        </v-badge>
      </div>
    </v-app-bar>

    <div class="hidden-sm-and-down">
      <v-container fluid>
        <v-row
          class="my-0 py-2 nav-menu"
          align="center"
          justify="space-between"
          style="transform: translateY(52px); border-radius: 0"
        >
          <v-btn text>
            Construcción <br />
            y ferretería
          </v-btn>
          <v-btn text>
            Pisos, pinturas <br />
            y acabados
          </v-btn>
          <v-btn text> Herramientas y maquinarias <br /> </v-btn>
          <v-btn text>
            Baño, cocina <br />
            y aseo
          </v-btn>
          <v-btn text> Electro <br /> </v-btn>
          <v-btn text>
            Muebles y <br />
            organización
          </v-btn>
          <v-btn text>
            Decoración <br />
            e iluminación
          </v-btn>
          <v-btn text>
            Jardín <br />
            y mascotas
          </v-btn>
          <v-btn text>
            Jardín <br />
            y mascotas
          </v-btn>
        </v-row>
      </v-container>
    </div>
    <v-main style="padding: 28px 0px 0px">
      <router-view />
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";
export default {
  name: "App",
  components: {
    Footer,
  },
  data: () => ({
    //
  }),
};
</script>

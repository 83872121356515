<template>
    <v-container class="pt-16">
      <v-col
        class="mx-auto"
        sm="12"
        md="6"
        lg="4">
        <div class="text-h4 text-center  v-text-ctr">
        Bienvenido a
        <span class="font-weight-bold">Construrama</span>
        </div>
        <div class="pa-5">

          <v-text-field label="Celular" class="v-text-ctr"></v-text-field>
          <v-text-field label="Contraseña"></v-text-field>
        </div>
        <div class="px-lg-14 px-5 pb-4"
          align="center">
          <v-btn
            color="#001F5B"
            text
            white--text
            class="font-weight-regular"
            >
            Olvidé mi contraseña
          </v-btn>
        </div>
        <div class="px-lg-14 px-5 pb-4">
          <v-btn
            color="#FF7D2D"
            block
            white--text
            large
            class="rounded-lg"
            style="color:#FFFFFF;"
            >
            Iniciar sesión
          </v-btn>
        </div>
        <div class="px-lg-14 px-5"
          align="center">
          <v-btn
            class="font-weight-regular"
            color="#001F5B"
            text
            white--text
        :to="'/register'"
            >
            ¿No tienes cuenta? <span class="font-weight-bold">Registrate</span>
          </v-btn>
        </div>
      </v-col>
    </v-container>
</template>
<style scoped>
.v-main {
    background: #ffffff !important;
}
</style>
<script>
export default {
  name: "Login",
};
</script>

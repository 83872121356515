<template>
<v-container my-5>
    <h1 class="v-text-ctr mb-5">Búsqueda</h1>
    <v-row>
        <v-col
        xs="3"
        md="4"
        lg="4">
        <template>
        <div>
            <v-expansion-panels
            v-model="panel"
            :disabled="disabled"
            multiple
            >
            <v-expansion-panel>
                <v-expansion-panel-header class="v-text-ctr font-weight-regular text-h6">Categoría</v-expansion-panel-header>
                <v-expansion-panel-content>
                Some content
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header class="v-text-ctr font-weight-regular text-h6">Marca</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <template>
                        <v-container
                            class="px-0"
                            fluid
                        >
                            <v-radio-group v-model="radioGroup">
                            <v-radio
                                v-for="n in 3"
                                :key="n"
                                :label="`Marca ${n}`"
                                :value="n"
                                color="#102F8B"
                            ></v-radio>
                            </v-radio-group>
                        </v-container>
                    </template>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header class="v-text-ctr font-weight-regular text-h6">Precio</v-expansion-panel-header>
                <v-expansion-panel-content>
                                    
      <v-range-slider
        v-model="value"
        step="10"
      ></v-range-slider>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </div>
        </template>
        </v-col>
        <v-col
        xs="9"
        md="8"
        lg="8">
       <AllProducts/>
        </v-col>
    </v-row>
</v-container>
</template>
<style scoped>
.v-expansion-panel:before {
    box-shadow: none;
}
</style>
<script>
import AllProducts from '@/components/products/AllProducts.vue'
export default {
    name: "Products",
    components: {
        AllProducts
    },
    data () {
      return {
        radioGroup: 1,
        panel: [1],
        disabled: false,
      }
    },
};
</script>

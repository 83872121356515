<template>
  <v-container>
    <div class="text-h4 font-weight-bold v-text-ctr my-5">Resumen</div>
    <v-row>
      <v-col md="8" lg="9">
        <v-card class="mx-auto rounded-0" elevation="0">
          <v-list-item three-line>
            <v-img
              alt="Vuetify Name"
              class="shrink mt-1 mr-3"
              contain
              min-width="100"
              src="~@/assets/image3.png"
            />
            <v-list-item-content>
              <div class="text-h5 font-weight-medium v-text-ctr">
                Cemento Quisqueya Zona Costera
              </div>
              <div class="text-h6 font-weight-regular grey--text">
                Precio unitario $35.000
              </div>
              <div class="d-flex align-center">
                <div class="d-flex align-center">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="#EEF2FF"
                    elevation="0"
                  >
                    <v-icon dark> mdi-minus </v-icon>
                  </v-btn>
                  <span class="text-h5 font-weight-medium v-text-ctr"> 1 </span>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="#EEF2FF"
                    elevation="0"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </div>
                <div class="text-h5 font-weight-bold v-text-ctr ms-5">
                  $70.000
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col md="4" lg="3">
        <v-card class="mx-auto rounded-0" elevation="0">
          <v-card-text>
            <div class="text-h6 font-weight-bold v-text-ctr my-5">Resumen</div>
            <table width="100%">
              <tr>
                <td class="grey--text py-2 fs-18">Subtotal</td>
                <td class="text-right grey--text py-2 fs-18">$140.000</td>
              </tr>
              <tr>
                <td class="grey--text py-2 fs-18">Descuento con cupón</td>
                <td class="text-right grey--text py-2 fs-18">- S/ 10.00</td>
              </tr>
              <tr>
                <td class="v-text-ctr py-2 fs-18">Monto final</td>
                <td class="text-right v-text-ctr py-2 fs-18">$130.000</td>
              </tr>
            </table>
            <v-btn
              color="#FF7D2D"
              white--text
              style="color: #ffffff"
              large
              block
              elevation="0"
              class="my-5"
              :to="'/login'"
            >
              Confirmar pedido
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Resumen",
};
</script>

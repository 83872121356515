<template>
  <div class="home">
    <v-container>
      <v-img
        :aspect-ratio="16 / 9"
        max-height="618"
        src="~@/assets/banner.png"
      ></v-img>
    </v-container>
    <v-container class="fill-height">
      <div class="text-h4 font-weight-bold v-text-ctr my-5">Categoría</div>
      <v-row justify="center" class="my-4">
        <v-col sm="1" md="1" lg="1">
          <div
            class="white py-4 rounded-lg"
            elevation="2"
            style="filter: drop-shadow(0px 3px 6px rgba(51, 98, 123, 0.102))"
          >
            <v-img contain src="~@/assets/cement.png" height="89px" />
          </div>
          <div
            class="subtitle-2 text-center font-weight-medium v-text-ctr py-2"
          >
            Cemento
          </div>
        </v-col>
        <v-col sm="1" md="1" lg="1">
          <div
            class="white py-4 rounded-lg"
            elevation="2"
            style="filter: drop-shadow(0px 3px 6px rgba(51, 98, 123, 0.102))"
          >
            <v-img contain src="~@/assets/tools.png" height="89px" />
          </div>
          <div
            class="subtitle-2 text-center font-weight-medium v-text-ctr py-2"
          >
            Carpintería
          </div>
        </v-col>
        <v-col sm="1" md="1" lg="1">
          <div
            class="white py-4 rounded-lg"
            elevation="2"
            style="filter: drop-shadow(0px 3px 6px rgba(51, 98, 123, 0.102))"
          >
            <v-img contain src="~@/assets/paint-bucket.png" height="89px" />
          </div>
          <div
            class="subtitle-2 text-center font-weight-medium v-text-ctr py-2"
          >
            Carpintería
          </div>
        </v-col>
        <v-col sm="1" md="1" lg="1">
          <div
            class="white py-4 rounded-lg"
            elevation="2"
            style="filter: drop-shadow(0px 3px 6px rgba(51, 98, 123, 0.102))"
          >
            <v-img contain src="~@/assets/power-plug.png" height="89px" />
          </div>
          <div
            class="subtitle-2 text-center font-weight-medium v-text-ctr py-2"
          >
            Carpintería
          </div>
        </v-col>
        <v-col sm="1" md="1" lg="1">
          <div
            class="white py-4 rounded-lg"
            elevation="2"
            style="filter: drop-shadow(0px 3px 6px rgba(51, 98, 123, 0.102))"
          >
            <v-img contain src="~@/assets/XMLID_162_.png" height="89px" />
          </div>
          <div
            class="subtitle-2 text-center font-weight-medium v-text-ctr py-2"
          >
            Carpintería
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <div class="d-flex">
        <div class="text-h4 font-weight-bold v-text-ctr my-5">Nuevos productos</div>
        <v-spacer></v-spacer>
        <v-btn
          :to="'/products'"
          class="font-weight-bold px-10"
          outlined
          color="#FF7D2D">
          Ver todo
        </v-btn>
      </div>
      <AllProducts />
    </v-container>

    <v-container class="fill-height">
      <div class="text-h4 font-weight-bold v-text-ctr my-5">Servicios e información</div>
      <v-row justify="center" class="my-4">
        <v-col sm="6" lg="2" md="6">
          <v-card class="rounded-0 pt-3" elevation="0">
            <v-img contain src="~@/assets/tienda1.png" height="59px" />
            <div class="v-text-ctr text-h6 text-center pt-3">
              Nuestras tiendas
            </div>
            <div class="grey--text subtitle-1 text-center py-2">
              21 tiendas <br />
              afiliadas
            </div>
          </v-card>
        </v-col>
        <v-col sm="6" lg="2" md="6">
          <v-card class="rounded-0 pt-3" elevation="0">
            <v-img contain src="~@/assets/car.png" height="59px" />
            <div class="v-text-ctr text-h6 text-center pt-3">
              Opciones de entrega
            </div>
            <div class="grey--text subtitle-1 text-center py-2">
              Retira en tienda o despacho en tienda
            </div>
          </v-card>
        </v-col>
        <v-col sm="6" lg="2" md="6">
          <v-card class="rounded-0 pt-3" elevation="0">
            <v-img contain src="~@/assets/dev.png" height="59px" />
            <div class="v-text-ctr text-h6 text-center pt-3">
              Nuestras tiendas
            </div>
            <div class="grey--text subtitle-1 text-center py-2">
              Te damos plazo de <br />
              una semana
            </div>
          </v-card>
        </v-col>
        <v-col sm="6" lg="2" md="6">
          <v-card class="rounded-0 pt-3" elevation="0">
            <v-img
              contain
              src="~@/assets/ConectateMaestro_Logo1.png"
              height="59px"
            />
            <div class="v-text-ctr text-h6 text-center pt-3">
              Nuestras tiendas
            </div>
            <div class="grey--text subtitle-1 text-center py-2">
              Nuestro programa de <br />
              puntos y compras
            </div>
          </v-card>
        </v-col>
        <v-col sm="6" lg="2" md="6">
          <v-card class="rounded-0 pt-3" elevation="0">
            <v-img contain src="~@/assets/Lucas.png" height="59px" />
            <div class="v-text-ctr text-h6 text-center pt-3">
              Nuestras tiendas
            </div>
            <div class="grey--text subtitle-1 text-center py-2">
              Nuevo programa <br />
              de canje y compra
            </div>
          </v-card>
        </v-col>
        <v-col sm="6" lg="2" md="6">
          <v-card class="rounded-0 pt-3" elevation="0">
            <v-img contain src="~@/assets/Union.png" height="59px" />
            <div class="v-text-ctr text-h6 text-center pt-3">
              Nuestras tiendas
            </div>
            <div class="grey--text subtitle-1 text-center py-2">
              Solicita asesoría <br />
              personalizada aquí
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import AllProducts from "@/components/products/AllProducts.vue";

export default {
  name: "Home",
  components: {
    AllProducts,
  },
};
</script>

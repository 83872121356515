<template>
    <v-col cols="12" md="6" class="mx-auto">
        <v-card class="ma-5 rounded-lg" elevation="0">
            <v-card-text>
                <div class="text-h3 text-center font-weight-bold v-text-ctr my-5">
                    ¡Compra exitosa!
                </div>
                <div class="text-h6 text-center font-weight-medium v-text-ctr my-5">
                    <p class="mb-0">Cemex ID #1236</p>
                    <p class="mb-0">Nº de pedido: 89634783578</p>
                    <p class="mb-0">Fecha: 21/09 14:45:00</p>
                </div>
                <v-divider></v-divider>
                <div class="d-flex flex-row justify-center my-5">
                        <v-img
                        alt="Vuetify Name"
                        class="shrink mt-1 mr-3"
                        contain
                        width="90"
                        src="~@/assets/image3.png"
                        />

                    <div class="d-flex flex-column">
                        <div class="text-h6 font-weight-medium v-text-ctr">
                            Cemento Quisqueya Zona Costera
                        </div>
                        <div class="text-h6 font-weight-regular grey--text">
                            2 unidades
                        </div>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-col>
</template>
<script>
export default {
  name: "CompraExitosa",
};
</script>

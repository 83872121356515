<template>
  <div>
    <div class="text-h5 font-weight-medium v-text-ctr my-5">
      Datos de contacto
    </div>
    <v-row class="pt-4">
      <v-col cols="12" md="6">
        <div class="fs-18 font-weight-medium v-text-ctr">Nombres</div>
        <v-text-field solo label="Nombres"></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <div class="fs-18 font-weight-medium v-text-ctr">Apellido</div>
        <v-text-field solo label="Apellido"></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <div class="fs-18 font-weight-medium v-text-ctr">Nº de cédula</div>
        <v-text-field solo label="Nº de cédula"></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <div class="fs-18 font-weight-medium v-text-ctr">Nº de celular</div>
        <v-text-field solo label="Nº de celular"></v-text-field>
      </v-col>

      <v-col cols="12" md="12">
        <div class="fs-18 font-weight-medium v-text-ctr">Email</div>
        <v-text-field solo label="Email"></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "PersonalInformation",
};
</script>

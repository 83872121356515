<template>
  <v-container class="my-5">
    <v-row>
      <v-col
        v-for="n in 4"
        :key="n"
        cols="12"
        sm="12"
        md="6"
        lg="3"
        class="mb-4"
      >
        <v-card
            class="mx-auto rounded-lg"
            max-width="374"
            elevation="0"
        >

          <div class="d-flex flex-lg-column flex-row">
            <v-img
            :aspect-ratio="1/1"
            contain
            min-width="95px"
            src="~@/assets/image2.png"
            ></v-img>


            <div>
              <v-card-text class="pb-lg-2 pb-1">
                <div class="grey--text v-text-ctr text-caption">
                Cemento
                </div>

                <div class="text-subtitle-1 v-text-ctr  font-weight-medium">
                    Cemento Quisqueya Zona Costera
                </div>

              </v-card-text>
              <v-card-actions class="align-sm-center align-lg-stretch flex-lg-column flex-row justify-space-around pa-lg-4 pa-2">
                <div class="v-text-ctr font-weight-medium text-left mb-lg-4 my-0">$ 50.000</div>
                  <v-btn
                      :to="'/product'"
                      color="#FF7D2D"
                      white--text
                      large
                      class="rounded-lg"
                      style="color:#FFFFFF;"
                  >
                      <v-icon left>
                          mdi-cart
                      </v-icon>
                      Añadir
                  </v-btn>
              </v-card-actions>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AllProducts",
};
</script>
<template>
  <v-form>
    <v-container class="py-16">
        <div class="text-h4 text-center  v-text-ctr">
        Regístrate en 
        <span class="font-weight-bold">Construrama</span>
        </div>
        <v-row class="py-14">
            <v-col
            cols="12"
            md="6"
            >
            <v-text-field
                label="Nombres"
            ></v-text-field>
            </v-col>

            <v-col
            cols="12"
            md="6"
            >
            <v-text-field
                label="Apellidos"
            ></v-text-field>
            </v-col>

            <v-col
            cols="12"
            md="6"
            >
            <v-text-field
                label="Cédula"
            ></v-text-field>
            </v-col>
            
            <v-col
            cols="12"
            md="6"
            >

            <v-select
            label="Ocupación"
            ></v-select>
            </v-col>
            
            <v-col
            cols="12"
            md="6"
            >
            <v-text-field
                label="Correo (opcional)"
            ></v-text-field>
            </v-col>
            
            <v-col
            cols="12"
            md="6"
            >
            <v-text-field
                label="Número celular"
            ></v-text-field>
            </v-col>
            
            <v-col
            cols="12"
            md="6"
            >
            <v-text-field
                label="Contraseña"
                v-model="password"
                :type="password"
            ></v-text-field>
            </v-col>
            
            <v-col
            cols="12"
            md="6"
            >
            <v-text-field
                label="Repetir contraseña"
                v-model="password"
                :type="password"
            ></v-text-field>
            </v-col>
        </v-row>
        <div
          align="center">
            <v-checkbox
            color="#FD4F1E"
            label="Acepto los términos y condiciones."
            ></v-checkbox>
        </div>
        <div
          align="center">
          <v-btn
            color="#FF7D2D"
            white--text
            large
            class="rounded-lg px-16"
            style="color:#FFFFFF;"
            elevation="0"
            >
            Regístrate
          </v-btn>
        </div>
    </v-container>
  </v-form>
</template>
<script>
export default {
  name: "Register",
  data () {
      return {

        password: 'Password',
      }
  }
};
</script>
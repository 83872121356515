<template>
  <v-footer padless>
    <v-card
      style="background: #001f5b; color: #ffffff"
      class="flex white--text"
      flat
      tile
    >
      <v-card-text class="py-2 white--text">
        <v-container>
          <div class="d-flex align-center">
            <v-img
              alt="Vuetify Name"
              class="shrink mt-1 mr-3"
              contain
              max-width="200"
              src="~@/assets/Logo-footer.png"
            />
            <v-spacer></v-spacer>
            <div>
              <ul
                class="font-weight-medium pa-0"
                style="text-decoration: none; list-style: none"
              >
                <li class="font-weight-medium fs-16" style="color: #ffffff">
                  Contacto
                </li>
                <li class="font-weight-regular fs-16" style="color: #ffffff">
                  (+57) 64585684
                </li>
                <li class="font-weight-regular fs-16" style="color: #ffffff">
                  contacto@construrama.co
                </li>
              </ul>
            </div>
            <v-spacer></v-spacer>
            <div>
              <ul
                class="font-weight-medium pa-0"
                style="text-decoration: none; list-style: none"
              >
                <li class="font-weight-medium fs-16" style="color: #ffffff">
                  Servicio al cliente
                </li>
                <li class="font-weight-regular fs-16" style="color: #ffffff">
                  Políticas de privacidad
                </li>
                <li class="font-weight-regular fs-16" style="color: #ffffff">
                  Términos y condiciones
                </li>
              </ul>
            </div>
            <v-spacer></v-spacer>
            <div>
              <ul class="pa-0" style="text-decoration: none; list-style: none">
                <li class="font-weight-medium fs-16" style="color: #ffffff">
                  Mi cuenta
                </li>
                <li class="font-weight-regular fs-16" style="color: #ffffff">
                  Regístrate
                </li>
                <li class="font-weight-regular fs-16" style="color: #ffffff">
                  Iniciar sesión
                </li>
              </ul>
            </div>
            <v-spacer></v-spacer>
            <v-btn v-for="icon in icons" :key="icon" class="mx-2" dark icon>
              <v-icon size="24px">
                {{ icon }}
              </v-icon>
            </v-btn>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
export default {
  name: "Footer",
  data: () => ({
    icons: ["mdi-facebook", "mdi-instagram"],
  }),
};
</script>

<template>
  <v-card class="ma-10 rounded-lg" elevation="0">
    <v-tabs vertical>
      <v-tab class="mb-4"> Perfil </v-tab>
      <v-tab class="mb-4"> Pedidos Activos </v-tab>
      <v-tab class="mb-4"> Historial de pedidos </v-tab>

      <v-tab-item>
        <v-card flat class="rounded-0 ml-lg-12 ml-1 mb-4">
          <v-card-text>
            <div class="d-flex flex-row">
              <v-img
                alt="Vuetify Name"
                class="shrink mt-1 mr-3"
                contain
                max-width="200"
                src="~@/assets/user.png"
              />
              <div>
                <div class="text-h5 font-weight-bold v-text-ctr my-5">
                    Juan Carlos Perales
                </div>
                <div class="text-h6 font-weight-regular v-text-ctr">
                    12.345.678
                </div>
                <div class="text-h6 font-weight-regular v-text-ctr">
                    jcperales@outlook.co
                </div>
              </div>
              <v-btn
                color="#FF7D2D"
                small
                dark
                absolute
                top
                bottom
                right
                fab
                elevation="1"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
        <v-card flat class="rounded-0 ml-lg-12 ml-1">
          <v-card-text>
            <div class="d-flex flex-row">
                <div class="text-h5 font-weight-bold v-text-ctr my-5">
                    Línea de crédito
                </div>
                <v-spacer></v-spacer>
                <div class="text-h5 font-weight-regular v-text-ctr my-5">
                    $ 1.000.000
                </div>
            </div>
            <v-divider></v-divider>
                <div class="text-h4 font-weight-bold v-text-ctr my-5">
                    ¡ConéctateMaestro!
                </div>
            <div class="d-flex flex-row">
                <div class="text-h5 font-weight-bold v-text-ctr my-5">
                    Dinero disponible
                </div>
                <v-spacer></v-spacer>
                <div class="text-h5 font-weight-regular v-text-ctr my-5">
                    $ 2.000.000
                </div>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat class="rounded-lg mx-4">
          <v-card-text>
            <p>
              Morbi nec metus. Suspendisse faucibus, nunc et pellentesque
              egestas, lacus ante convallis tellus, vitae iaculis lacus elit id
              tortor. Sed mollis, eros et ultrices tempus, mauris ipsum aliquam
              libero, non adipiscing dolor urna a orci. Curabitur ligula sapien,
              tincidunt non, euismod vitae, posuere imperdiet, leo. Nunc sed
              turpis.
            </p>

            <p>
              Suspendisse feugiat. Suspendisse faucibus, nunc et pellentesque
              egestas, lacus ante convallis tellus, vitae iaculis lacus elit id
              tortor. Proin viverra, ligula sit amet ultrices semper, ligula
              arcu tristique sapien, a accumsan nisi mauris ac eros. In hac
              habitasse platea dictumst. Fusce ac felis sit amet ligula pharetra
              condimentum.
            </p>

            <p>
              Sed consequat, leo eget bibendum sodales, augue velit cursus nunc,
              quis gravida magna mi a libero. Nam commodo suscipit quam. In
              consectetuer turpis ut velit. Sed cursus turpis vitae tortor.
              Aliquam eu nunc.
            </p>

            <p>
              Etiam ut purus mattis mauris sodales aliquam. Ut varius tincidunt
              libero. Aenean viverra rhoncus pede. Duis leo. Fusce fermentum
              odio nec arcu.
            </p>

            <p class="mb-0">
              Donec venenatis vulputate lorem. Aenean viverra rhoncus pede. In
              dui magna, posuere eget, vestibulum et, tempor auctor, justo.
              Fusce commodo aliquam arcu. Suspendisse enim turpis, dictum sed,
              iaculis a, condimentum nec, nisi.
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              Fusce a quam. Phasellus nec sem in justo pellentesque facilisis.
              Nam eget dui. Proin viverra, ligula sit amet ultrices semper,
              ligula arcu tristique sapien, a accumsan nisi mauris ac eros. In
              dui magna, posuere eget, vestibulum et, tempor auctor, justo.
            </p>

            <p class="mb-0">
              Cras sagittis. Phasellus nec sem in justo pellentesque facilisis.
              Proin sapien ipsum, porta a, auctor quis, euismod ut, mi. Donec
              quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nam
              at tortor in tellus interdum sagittis.
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
<style scoped>
.v-tabs--vertical > .v-tabs-bar .v-tab {
  justify-content: start;
  color: #001f5b !important;
  border-radius: 5px;
  background-color: #ffffff;
}
.v-tabs--vertical > .v-tabs-bar .v-tab--active {
  background: #2a93e4;
  color: #ffffff !important;
  justify-content: start;
}.v-btn--fab.v-size--small.v-btn--absolute.v-btn--top {
    top: 18px;
}
</style>
<script>
export default {
  name: "Profile",
};
</script>

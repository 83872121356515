<template>
  <div>
    <div class="text-h5 font-weight-medium v-text-ctr my-5">
      Datos de entrega
    </div>
    <v-row class="pt-4">
      <v-col cols="12" md="12">
        <v-radio-group>
          <v-radio value="Google" color="#102F8B" style="align-items: inherit">
            <template v-slot:label>
              <div>
                <div class="sutitle-1 font-weight-medium v-text-ctr">
                  Recojo en tienda
                </div>
                <p>A partir del 23/04</p>
                <div class="sutitle-1 font-weight-medium v-text-ctr">
                  Fecha y hora de entrega
                </div>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Fecha"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
            </template>
          </v-radio>
          <v-radio
            value="Duckduckgo"
            color="#102F8B"
            style="align-items: inherit"
          >
            <template v-slot:label>
              <div>
                <div class="sutitle-1 font-weight-medium v-text-ctr">
                  Despacho a domicilio
                </div>
                <p>Seleccione dirección</p>
                <v-radio value="Google" style="align-items: inherit">
                  <template v-slot:label>
                    <div>
                      <div class="sutitle-2 font-weight-medium v-text-ctr">
                        Casa
                      </div>
                      <p class="v-text-ctr">
                        Avenida Haya de la Torre 568 - La Perla - Callao
                      </p>
                    </div>
                  </template>
                </v-radio>
                <v-radio value="Duckduckgo" style="align-items: inherit">
                  <template v-slot:label>
                    <div>
                      <div class="sutitle-2 font-weight-medium v-text-ctr">
                        Nueva dirección
                      </div>
                      <p class="v-text-ctr">
                        Avenida Haya de la Torre 568 - La Perla - Callao
                      </p>
                    </div>
                  </template>
                </v-radio>
                <v-col cols="12" md="12">
                  <div class="fs-18 font-weight-medium v-text-ctr">Alias</div>
                  <v-text-field solo  label="Alias"></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <div class="fs-18 font-weight-medium v-text-ctr">
                    Dirección
                  </div>
                  <v-text-field solo label="Dirección"></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <div class="fs-18 font-weight-medium v-text-ctr">
                    Referencia
                  </div>
                  <v-text-field solo label="Referencia"></v-text-field>
                </v-col>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
.mdi-calendar::before {
  color: rgb(250 125 46);
}
</style>
<script>
export default {
  name: "DeliveryComponent",
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    disabled: false,
    readonly: false,
  }),
};
</script>

<template>
  <v-card class="mx-auto rounded-0" elevation="0">
    <v-card-text>
      <div class="text-h6 font-weight-bold v-text-ctr my-5">Resumen</div>
      <div class="d-flex flex-row mb-5">
            <v-img
            alt="Vuetify Name"
            class="shrink mt-1 mr-3"
            contain
            width="90"
            src="~@/assets/image3.png"
            />

        <div class="d-flex flex-column">
            <div class="text-h6 font-weight-medium v-text-ctr">
                Cemento Quisqueya Zona Costera
            </div>
            <div class="text-h6 font-weight-regular grey--text">
                2 unidades
            </div>
        </div>
      </div>
      <table width="100%">
        <tr>
          <td class="grey--text py-2 fs-18">Subtotal</td>
          <td class="text-right grey--text py-2 fs-18">$140.000</td>
        </tr>
        <tr>
          <td class="grey--text py-2 fs-18">Descuento</td>
          <td class="text-right grey--text py-2 fs-18">- S/ 10.00</td>
        </tr>
        <tr>
          <td class="grey--text py-2 fs-18">Flete</td>
          <td class="text-right grey--text py-2 fs-18">S/ 10.00</td>
        </tr>
        <th></th>
        <tr>
          <td class="v-text-ctr py-2 fs-18 font-weight-medium">Monto final</td>
          <td class="text-right v-text-ctr py-2 fs-18 font-weight-medium">
            $130.000
          </td>
        </tr>
        <tr>
          <td style="color: #27ae60" class="py-2 fs-18 font-weight-medium">
            Ahorraste
          </td>
          <td
            style="color: #27ae60"
            class="text-right py-2 fs-18 font-weight-medium"
          >
            $10.000
          </td>
        </tr>
      </table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "SummaryComponent",
};
</script>

<template>
  <div class="home">
    <v-container>
      <h1 class="text-center my-10">Selecciona una tienda</h1>

        <AllShops/>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import AllShops from '@/components/AllShops.vue'

export default {
  name: 'Inicio',
  components: {
    AllShops
  }
}
</script>
